import { Component, OnInit, OnDestroy, ViewChild , ElementRef, Output, EventEmitter }  from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { FilterService } from 'src/app/filter/filter.service';
import { Agent } from 'http';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-tour-user-details',
  templateUrl: './tour-user-details.component.html',
  styleUrls: ['./tour-user-details.component.scss']
})
export class TourUserDetailsComponent implements OnInit {
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter();
  addUserForm: FormGroup;
  allTour: any;
  public value: boolean = false;
  public selectFinancialyear: any;
  public getFinancialYear: Array<any> = [];
  fornviewID: string[] = [];
  backviewID: string[] = [];
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  @ViewChild('Select') Select: ElementRef;
  @ViewChild('Tour_Type') Tour_Type: ElementRef;
  @ViewChild('Month') Month: ElementRef;
  @ViewChild('status') status: ElementRef;
  allDate: any
  //public accordionCount = 10; // Number of travelers
  accordionCount: number = 0;
  frontView: File;
  dtOptions: any = {};
  allData: any;
  private category: string;
  private userid: any;
  TourID: any;
  minDate = { year: 1900, month: 1, day: 1 };
  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  data: any;
  dateList: any;
  tourId: any;
  DOB: [''];
  age: [''];
  response=[];
  responsetraveler: any[] = [];
  traveler: any[] = [];
  user_id: any;
  tour_id: any;
  mytourids: any;
  myUserId: any;
  id: any;
  newID: any;
  selectedPackageType: any;
  myData: any;
  roomdata: any;
  boardingdata: any;
  resp: any;
  country: any;
  date: any;
  dataID: any;
  user: any;
  dataNew: any[] = [];
  uploadedFileName: string[] = [];
  selectedFileName: string[] = [];
  uploadedImage: any[] = [];
  uploadedImageBack: any[] = [];
  private _iddata: any;
  userIddata: any;
  applyTourID: any;
  apiHost: string;
  travelersArrays: any;
  number: number;
  newNumber: any=[];
  checker: boolean=true;
  responses: any;
  AgentID: any;
csvResponse:any=[]
  alldesti: any;
  destination: any;
  // idNumberInput: any;
  constructor(
    
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private filter:FilterService
  ) {
  
  }
  @ViewChild('idNumberInput', { static: false }) idNumberInput!: ElementRef;


  ngOnInit(): void {
    this.apiHost = environment.API_HOST;
    var yearsLength = 5;
    this.selectFinancialyear = ""
    var currentYear = new Date().getFullYear();
    for(var i = 0; i < 5; i++){
      var next = currentYear+1;
      var year = currentYear + '-' + next.toString().slice(-2);
      // $('#financialYear').append(new Option(year, year));
      this.getFinancialYear.unshift(year)
      currentYear--;
    }
    this.addUserForm = this.fb.group({
      travelers: this.fb.array([])
    });
    this.dataID = this.route.snapshot.paramMap.get('_id');
    this.category = this.router.url.split('/')[1];
    if (this.category === 'tour-user-details') {
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      scrollX: true,
      dom: 'Brftip',
      buttons: [
        { extend: 'copyHtml5', footer: true, title: 'Glabol Admin - User List' },
        { extend: 'excelHtml5', footer: true, title: 'Glabol Admin - User List' },
        // { extend: 'csvHtml5', footer: true, title: 'Glabol Admin - User List' },
        { extend: 'pdfHtml5', footer: true, pageSize: 'A3', title: 'Glabol Admin - User List' }
      ],
    };
    this.tour();
    this.activeTour();
    this.admin();
    this.desti();
    this.travelersArrays
    
    //this.addTravelerForms();
  }


  onIdProofChange(selectedValue: string) {
    const inputElement = this.idNumberInput.nativeElement;

    if (selectedValue === 'Adharcard') {
      inputElement.setAttribute('pattern', '\\d*'); // Allow only numbers
    } else {
      inputElement.removeAttribute('pattern'); // Allow alphanumeric characters
    }
  }

  onlyNumberKey(event: KeyboardEvent) {
    const selectedValue = (document.querySelector('select[formControlName="Idproof"]') as HTMLSelectElement).value;
    if (selectedValue === 'Adharcard') {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      }
    }
    return true;
  }
  callFunction(){

    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;
    const Select = this.Select?.nativeElement?.value;
    const Tour_Type = this.Tour_Type?.nativeElement?.value;
    const Month = this.Month?.nativeElement?.value;
   
   
    //console.log(this.allDate._id)
    const orderDetails :any = {
    tour_id: this.TourID || '',
    batch_id: dateSchedule,
    payment_status: status ? status : '',
    Select:Select ? Select :'',
    Tour_Type: Tour_Type ? Tour_Type : '',
    Month: Month ? Month : '',
    Financial_Year: this.selectFinancialyear
    };
    this.checker=false

    this.filter.filterStart+=1
    const target = event.target as HTMLElement;
    // console.log('Scrolled to:', target.scrollTop);
    this.apiService.allappiedusertourV1(this.filter.filterStart,orderDetails).subscribe((resp: any) => {
     
    if(resp){
      //  console.log(resp,"?>????>>>>>>");
       this.checker=true
       let temp = [...this.response];
       temp = [...temp, ...resp.userData]; 
       this.response = temp; 
      // console.log(this.response)

   
     this.travelersArrays = resp.userData.map(user => 
      user.userTourDetail.map(detail => detail.travelers || [])
  );
  // console.log(this.travelersArrays,">>>>>>>>>>>>");
 
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }}
    });
    
  }
  

  onScroll(event: Event): void {
 if(this.checker==true){
   this.callFunction()
  }
  }

  tour() {
    console.log(this.TourID)

    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;
    const Select = this.Select?.nativeElement?.value;
    const Tour_Type = this.Tour_Type?.nativeElement?.value;
    const Month = this.Month?.nativeElement?.value;
    //console.log(this.allDate._id)
    const orderDetails :any = {
    tour_id: this.TourID || '',
    batch_id: dateSchedule,
    payment_status: status ? status : '',
    Select:Select ? Select :'',
    Tour_Type: Tour_Type ? Tour_Type : '',
    Month: Month ? Month : '',
    Financial_Year: this.selectFinancialyear
    };
    this.spinner.show()
    this.apiService.allappiedusertourV2( orderDetails).subscribe(async(res:any)=>{
      this.csvResponse= await res?.userData
    })

    this.apiService.allappiedusertourV1(this?.filter?.filterStart,orderDetails).subscribe((resp: any) => {
      //console.log(resp, "getallTourPackageActive");
      this.spinner.hide()
      // console.log(resp,"?>????>>>>>>");
      
      this.response = resp?.userData
      // console.log(resp?.userData)

    //   this.responsetraveler = resp.userthis.response.flatMap(user => 
    //     user.userTourDetail.flatMap(detail => detail.travelers || [])
    // );
    //   console.log(this.responsetraveler);

     this.travelersArrays = resp.userData.map(user => 
      user.userTourDetail.map(detail => detail.travelers || [])
  );
  // console.log(this.travelersArrays,">>>>>>>>>>>>");
//   this.responsetraveler = travelersArrays.flat(1); 
// console.log(this.responsetraveler);
      
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  get travelersFormArray() {
    return this.addUserForm.get('travelers') as FormArray;
  }

  addUserFormAccordions(numTravelers: number) {
    this.travelersFormArray.clear(); // Clear existing form controls
    for (let i = 0; i < numTravelers; i++) {
      this.travelersFormArray.push(this.createTravelerForm());
    }
  }

  private createTravelerForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      DOB: ['', Validators.required],
      age: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      country: [''],
      state: ['', Validators.required],
      pincode: ['', Validators.required],
      city: ['', Validators.required],
      IdNumber: ['', Validators.required],
      Document_Front: ['null'],
      Document_Back: ['null'],
      packageType: [''],
      roomSharing: [''],
      boarding_point: [''],
      Idproof: [''],
    });
  }

  


  calculateAgeForTraveler(index: number) {
    const travelerForm = this.travelersFormArray.at(index);
    const dobValue = travelerForm.get('DOB').value; // "YYYY-MM-DD" format from input type="date"
  
    if (dobValue) {
      const dob = new Date(dobValue); // Directly use the date string
  
      if (!isNaN(dob.getTime())) {
        // Calculate age
        const now = new Date();
        let age = now.getFullYear() - dob.getFullYear();
        const monthDiff = now.getMonth() - dob.getMonth();
  
        // Adjust age if the birthday hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
          age--;
        }
  
        console.log('Calculated Age:', age);
        travelerForm.patchValue({ age: age });
      } else {
        console.error('Invalid date format');
      }
    } else {
      console.error('Date of Birth is missing');
    }
  }
  
  

  // Front
  onFileSelected(event: any, type: string, index: number) {
    const file: File = event.target.files[0];
    if (file) {
        this.uploadedFileName[index] = file.name;
        const reader = new FileReader();
        reader.onload = () => {
            this.uploadedImage[index] = reader.result;
        };
        reader.readAsDataURL(file);
    }
  } 

  onFileSelecteds(event: any, type: string, index: number) {
      const file: File = event.target.files[0];
      if (file) {
          this.selectedFileName[index] = file.name;
          const reader = new FileReader();
          reader.onload = () => {
              this.uploadedImageBack[index] = reader.result;
          };
          reader.readAsDataURL(file);
      }
  }


  User(userid: any, tourids: any, number: any, data: any, roomsharing: any, boardingpoint: any, applyTour: any) {
    console.log(userid, tourids, number, applyTour,roomsharing,boardingpoint );
    this.spinner.show();
    //return
    this.apiService.GetallCountry().subscribe((resp: any) => {
      this.spinner.hide();
      console.log(resp);
      this.country = resp;
      this.addUserFormAccordions(number); // Dynamically add accordions based on number of travelers
      this.myData = data;
      this.roomdata = roomsharing;
      this.boardingdata = boardingpoint;
      this.myUserId = userid;
      this.mytourids = tourids;
      this.applyTourID = applyTour
      this.edituser(this.applyTourID)
    });
  }


  // edituser(tour_id: any) {
  //   this.spinner.show();
  //   this.apiService.AlltourUserDetails(tour_id).subscribe((res: any) => {
  //     console.log(res, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  //     if (res.success == true) {
  //       this.spinner.hide();
  //       const travelersData = res?.data[0]?.travelers; // Assuming the traveler data is in the first element of the array
  //       this.travelersFormArray.clear(); 
  //       travelersData.forEach(traveler => {
  //         const travelerFormGroup = this.createTravelerForm();
  //         travelerFormGroup.patchValue(traveler);
  //         this.travelersFormArray.push(travelerFormGroup);
  //       });
  //       this.fornviewID = Array(this.travelersFormArray.length).fill('');
  //       this.backviewID = Array(this.travelersFormArray.length).fill('');
  //             // Initialize fornviewID and backviewID arrays
  //         this.fornviewID = [];
  //         this.backviewID = [];
  //        // Set image paths for each traveler
  //       travelersData.forEach((traveler, index) => {
  //         this.fornviewID[index] = traveler?.Document_Front[0];
  //         this.backviewID[index] = traveler?.Document_Back[0];
  //       });
  //     } else {
  //       this.spinner.hide();
  //       console.error("Invalid API response or travelers data is missing.");
  //     }
  //   });
  // }

  // onFileChange(event) {
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     let formData = new FormData();
  //     formData.append('uploadFile', file);
  //     this.apiService.uploadimages(formData).subscribe((result: any) => {
  //       // Check if result is valid before accessing properties
  //       if (result && result.file_data && result.file_data.length > 0) {
  //         const imagePath = result.file_data[0].src;
  //         this.fornviewID = imagePath;
  //         // Ensure that this.addUserForm is not null before accessing form controls
  //         if (this.addUserForm) {
  //           const travelersArray = this.addUserForm.get('travelers') as FormArray;
  //           // Loop through each traveler and set the Document_Front value
  //           travelersArray.controls.forEach((travelerControl: FormGroup) => {
  //             travelerControl.get('Document_Front')?.setValue(imagePath);
  //           });
  //         }
  //       }
  









  
  // }
  edituser(tour_id: any) {
    this.spinner.show();
    this.apiService.AlltourUserDetails(tour_id).subscribe((res: any) => {
      // console.log(res, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      if (res.success == true) {
        this.spinner.hide();
        const travelersData = res?.data[0]?.travelers; // Assuming the traveler data is in the first element of the array
        this.travelersFormArray.clear();
        this.fornviewID = Array(this.travelersFormArray.length).fill('');
        this.backviewID = Array(this.travelersFormArray.length).fill('');     
        this.fornviewID = []; // Initialize fornviewID array
        this.backviewID = []; // Initialize backviewID array
        travelersData.forEach(traveler => {
          const travelerFormGroup = this.createTravelerForm();
          travelerFormGroup.patchValue(traveler);
          this.travelersFormArray.push(travelerFormGroup);
          this.fornviewID.push(traveler?.Document_Front[0]); // Set image path for Document_Front
          this.backviewID.push(traveler?.Document_Back[0]); // Set image path for Document_Back
        });
      } else {
        this.spinner.hide();
        console.error("Invalid API response or travelers data is missing.");
      }
    });
  }
  

  onFileChange(event: any, travelerIndex: number) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append('uploadFile', file);

      this.apiService.uploadimages(formData).subscribe(
        (result: any) => {
          console.log('Upload Result:', result);
          if (result && result.file_data && result.file_data.length > 0) {
            const imagePath = result.file_data[0].src;
            console.log('Image Path:', imagePath);

            // Initialize the fornviewID array if not already
            if (!this.fornviewID) {
              this.fornviewID = [];
            }

            // Ensure the index exists in fornviewID
            this.fornviewID[travelerIndex] = imagePath;

            // Ensure the traveler form exists before setting the value
            const travelerFormArray = this.addUserForm.get('travelers') as FormArray;
            if (travelerFormArray && travelerFormArray.at(travelerIndex)) {
              const travelerForm = travelerFormArray.at(travelerIndex);
              travelerForm.get('Document_Front')?.setValue(imagePath);
            } else {
              console.error(`Traveler form at index ${travelerIndex} does not exist.`);
            }
          } else {
            console.error('Unexpected API response format:', result);
          }
        },
        (error) => {
          console.error('Upload error:', error);
        }
      );
    } else {
      console.warn('No file selected for upload.');
    }
  }
  

  onFileChangeback(event, travelerIndex) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append('uploadFile', file);
      this.apiService.uploadimages(formData).subscribe((result: any) => {
        console.log(result);
        // this.backviewID = result?.file_data[0].src
        // console.log(this.backviewID)
        if (result && result.file_data && result.file_data.length > 0) {
          const imagePath = result.file_data[0].src;
          this.backviewID[travelerIndex] = imagePath;
          // Set the image path for the specific traveler
          const travelerForm = (this.addUserForm.get('travelers') as FormArray).at(travelerIndex);
          travelerForm.get('Document_Back')?.setValue(imagePath);
        }
        // if (result && result.file_data && result.file_data.length > 0) {
        //   const imagePath = result.file_data[0].src;
        //   this.backviewID = imagePath;
        //   // Ensure that this.addUserForm is not null before accessing form controls
        //   if (this.addUserForm) {
        //     const travelersArray = this.addUserForm.get('travelers') as FormArray;
        //     // Loop through each traveler and set the Document_Front value
        //     travelersArray.controls.forEach((travelerControl: FormGroup) => {
        //       travelerControl.get('Document_Back')?.setValue(imagePath);
        //     });
        //   }
        // }
      });
    }
  }


  onSubmit() {
    console.log(this.addUserForm.value);
    //return
    this.apiService.updatetourallUser(this.addUserForm.value, this.applyTourID).subscribe((resp: any) => {
    this.toastr.success('User added successfully!');
  },
    (error) => {
      console.error(error);
      this.toastr.error('Failed to add user. Please try again later.');
    }
  );
  }

  // onlyNumberKey(evt: KeyboardEvent) {
  //   let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
  //   return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && ASCIICode !== 46) ? false : true;
  // }

  activeTour() {
    this.apiService.getTrip().subscribe((res: any) => {
      //console.log(res, 'heeeeeeee');
      this.allTour = res.data;
      // this.dtTrigger.next();
      // this.isDtInitialized = true;
          // this.render = 0;
    });
  }

  onCategorySelect(id) {
    if (id) {
      let dataForForm = {
        id: id,
      };
      this.apiService.orderID(dataForForm).subscribe((res: any) => {
        this.allDate = res.getData;
        console.log(this.allDate._id);
        this.TourID = this.allDate._id;
      });
    } else {
      // Handle case when nothing is selected
      this.TourID = this.allTour._id; // or null, depending on your preference
    }
  }


  onAgentSelect(id) {
    let dataForForm = {
      id: id,

    };

    this.AgentID = id
    console.log(this.AgentID,">>>>");
    
   
  }

  financialList(){
    console.log(this.value, this.selectFinancialyear)
    if(this.selectFinancialyear == 'Select Year'){
      Swal.fire({
        text: 'Please select financial year',
        icon: 'warning',
      });
      return
    }
    this.spinner.show();
    this.apiService.financialYearReportList({name: this.selectFinancialyear}).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res)
      if(res.success){
        this.response = res.getData
        console.log(this.response)
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    })
  }

  userreport() {
    console.log(this.TourID)
    //return
   
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;
    const Select = this.Select?.nativeElement?.value;
    const Tour_Type = this.Tour_Type?.nativeElement?.value;
    const Month = this.Month?.nativeElement?.value;
   
   
    //console.log(this.allDate._id)
    const orderDetails = {
    tour_id: this.TourID || '',
    batch_id: dateSchedule,
    payment_status: status ? status : '',
    domesticorInternational:Select ? Select :'',
    tour_type: Tour_Type ? Tour_Type : '',
    travelMonth: Month ? Month : '',
    financialYear: this.selectFinancialyear,
    bookedBy : this.AgentID || '',
    };
    console.log(orderDetails);
    //return
    this.spinner.show();
    this.apiService.trvellerSerach(orderDetails).subscribe((res: any) => {
      console.log(res,"sssssssssssssssssssssssssssss");
        if (res.success) {
          this.checker=false
          this.spinner.hide();
          this.response = res.userData;
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
    });
  }


  exportToCSV() {
    this.spinner.show(); 
    
    let csvContent = "data:text/csv;charset=utf-8,";

    const header = "S. No.,User Name,Tour Name,Tour User No.,Mobile Number,Date & Time,Agent Name,Amount,Amount Paid, Remaining Amount,Status,Email,DOB,Country,PackageType,Pincode,RoomSharing,State,Id Proof,Id Number,Age,Boarding Point,City";
    csvContent += header + "\r\n";

    const formatDOB = (dob) => {
        if (dob && dob.year !== undefined && dob.month !== undefined && dob.day !== undefined) {
            const { year, month, day } = dob;
            return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        }
        return "";
    };

    const formatDate = (date) => {
        if (!date) return "";
        const d = new Date(date);
        return d.toISOString().slice(0, 19).replace('T', ' ');
    };


    this.csvResponse.forEach((data, index) => {
      console.log(this.csvResponse,">>>>>>>>>>");
      
        // Export main row
        const mainRow = [
            index + 1,
            data?.user_id?.firstname || "",
            data?.tour_id?.title || "",
            data?.old_travel_no_of_traveler || "",
            data?.user_id?.mobile || "",
            formatDate(data?.payment_time),
            (data?.bookedBy?.firstname || data?.bookedBy?.lastname)
            ? (data?.bookedBy?.firstname || 'N/A') + ' ' + (data?.bookedBy?.lastname || 'N/A') : 'N/A',
            data?.finalPrice || "",
            data?.amountPaid || "",
            data?.remainingAmount || "",
            data?.status || "",
            "", // Placeholder for traveler name
            ""  // Placeholder for traveler age
        ].join(",");

        csvContent += mainRow + "\r\n";

        const travelCount = Number(data?.old_travel_no_of_traveler) || 1;
        const travelersArrays = this.travelersArrays[index] || [];
this.number=travelCount
for (let i = 0; i < this.number; i++) {
 this.newNumber.push(i)
  // console.log(this.newNumber);
  
}


        if (Array.isArray(travelersArrays)) {
            let BlankRows = true;

            travelersArrays.forEach((travelersGroup) => {
                if (Array.isArray(travelersGroup) && travelersGroup.length > 0) {
                    BlankRows = false;  
                    travelersGroup.forEach((traveler, tIndex) => {
                        const nestedRow = [
                            String.fromCharCode(65 + tIndex),  
                            traveler.name || "",
                            data?.tour_id?.title || "",
                            data?.old_travel_no_of_traveler || "",
                            traveler.mobile || "",
                            formatDate(data?.payment_time),
                            "",
                            "",
                            "",
                            "",
                            data?.status || "",
                            traveler.email || "",
                            traveler.DOB || "",
                            traveler.country || "",
                            traveler.packageType || "",
                            traveler.pincode || "",
                            traveler.roomSharing || "",
                            traveler.state || "",
                            traveler.Idproof || "",
                            traveler.IdNumber || "",
                            traveler.age || "",
                            traveler.boarding_point || "",
                            traveler.city || "",
                            "",  
                            ""   
                        ].join(",");

                        csvContent += nestedRow + "\r\n";
                    });
                }
            });

        
            if (BlankRows) {
                for (let j = 0; j < travelCount; j++) {
                    const blankRow = [
                        String.fromCharCode(65 + j),  
                       
                        ""  
                    ].join(",");

                    csvContent += blankRow + "\r\n";
                }
            }
        } else {
            // console.log('TravelersArrays is not an array or is missing:', travelersArrays);
        }
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.spinner.hide(); 
}




exportToExcel() {
  this.spinner.show(); 
  const wsData = [];
  const header = [
    "S. No.", "User Name", "Tour Name", "Tour User No.", "Mobile Number",
    "Date & Time", "Agent Name","Amount","Amount Paid", "Remaining Amount", "Status", "Email", "DOB", "Country", 
    "PackageType", "Pincode", "RoomSharing", "State", 
    "Id Proof", "Id Number", "Age", "Boarding Point", "City"
  ];
  wsData.push(header);

  const formatDOB = (dob) => {
    if (dob && dob.year !== undefined && dob.month !== undefined && dob.day !== undefined) {
      const { year, month, day } = dob;
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }
    return "";
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().slice(0, 19).replace('T', ' ');
  };

  this.csvResponse.forEach((data, index) => {
   
    const mainRow = [
      index + 1,
      data?.user_id?.firstname || "",
      data?.tour_id?.title || "",
      data?.old_travel_no_of_traveler || "",
      data?.user_id?.mobile || "",
      formatDate(data?.payment_time),
      (data?.bookedBy?.firstname || data?.bookedBy?.lastname)
            ? (data?.bookedBy?.firstname || 'N/A') + ' ' + (data?.bookedBy?.lastname || 'N/A') : 'N/A',
      data?.finalPrice || "",
      data?.amountPaid || "",
      data?.remainingAmount || "",
      data?.status || "",
      data?.email || "",
      formatDOB(data?.DOB) || "",
      data?.country || "",
      data?.packageType || "",
      data?.pincode || "",
      data?.roomSharing || "",
      data?.state || "",
      data?.Idproof || "",
      data?.IdNumber || "",
      data?.age || "",
      data?.boarding_point || "",
      data?.city || "",
    ];
    wsData.push(mainRow);

    const travelCount = Number(data?.old_travel_no_of_traveler) || 1;
    const travelersArrays = this.travelersArrays[index] || [];
    
    if (Array.isArray(travelersArrays) && travelersArrays.length > 0) {
      travelersArrays.forEach((travelersGroup) => {
        if (Array.isArray(travelersGroup) && travelersGroup.length > 0) {
          travelersGroup.forEach((traveler, tIndex) => {
            const nestedRow = [
              String.fromCharCode(65 + tIndex), // A, B, C, etc.
              traveler.name || "",
              data?.tour_id?.title || "",
              data?.old_travel_no_of_traveler || "",
              traveler.mobile || "",
              formatDate(data?.payment_time),
              "",
              "",
              "",
              "",
              data?.status || "",
              traveler.email || "",
              traveler?.DOB || "",
              traveler.country || "",
              traveler.packageType || "",
              traveler.pincode || "",
              traveler.roomSharing || "",
              traveler.state || "",
              traveler.Idproof || "",
              traveler.IdNumber || "",
              traveler.age || "",
              traveler.boarding_point || "",
              traveler.city || "",
            ];
            wsData.push(nestedRow);
          });
        }
      });
    } else {
     
      for (let j = 0; j < travelCount; j++) {
        const blankRow = [
          String.fromCharCode(65 + j),  
          "",  // Placeholder for user name
          "",  // Placeholder for tour name
          "",  // Placeholder for tour user no.
          "",  // Placeholder for mobile number
          "",  // Placeholder for date & time
          "",  // Placeholder for status
          "",  // Placeholder for email
          "",  // Placeholder for DOB
          "",  // Placeholder for country
          "",  // Placeholder for package type
          "",  // Placeholder for pincode
          "",  // Placeholder for room sharing
          "",  // Placeholder for state
          "",  // Placeholder for id proof
          "",  // Placeholder for id number
          "",  // Placeholder for age
          "",  // Placeholder for boarding point
          "",  // Placeholder for city
        ];
        wsData.push(blankRow);
      }
    }
  });
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(wsData);
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  XLSX.writeFile(workbook, 'GlabolUserdetails.xlsx');
  this.spinner.hide(); 
}



exportToPDF() {
  this.spinner.show(); 
  const doc = new jsPDF('landscape', 'mm', 'a3'); // Set landscape orientation and A4 size

  doc.setFontSize(14);
  doc.text("Global User Details", doc.internal.pageSize.getWidth() / 2, 10, { align: "center" });

  const header = [
    "S. No.", "User Name", "Tour Name", "Tour User No.", "Mobile Number","Agent Name","Amount","Amount Paid"," Remaining Amount",
    "Date & Time", "Status", "Email", "DOB", "Country", 
    "Package Type", "Pincode", "Room Sharing", "State", 
    "ID Proof", "ID Number", "Age", "Boarding Point", "City"
  ];

  const rows = [];
  
  const formatDOB = (dob) => {
    if (dob && dob.year && dob.month && dob.day) {
      return `${dob.year}-${dob.month.toString().padStart(2, '0')}-${dob.day.toString().padStart(2, '0')}`;
    }
    return "";
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toLocaleString();
  };

  this.csvResponse.forEach((data, index) => {
    const mainRow = [
      index + 1,
      data?.user_id?.firstname || "",
      data?.tour_id?.title || "",
      data?.old_travel_no_of_traveler || "",
      data?.user_id?.mobile || "",
      (data?.bookedBy?.firstname || data?.bookedBy?.lastname)
            ? (data?.bookedBy?.firstname || 'N/A') + ' ' + (data?.bookedBy?.lastname || 'N/A') : 'N/A',
      data?.finalPrice || "",
      data?.amountPaid || "",
      data?.remainingAmount || "",
      formatDate(data?.payment_time),
      data?.status || "",
      data?.email || "",
       "",
      data?.country || "",
      data?.packageType || "",
      data?.pincode || "",
      data?.roomSharing || "",
      data?.state || "",
      data?.Idproof || "",
      data?.IdNumber || "",
      data?.age || "",
      data?.boarding_point || "",
      data?.city || "",
    ];

    rows.push(mainRow);

    const travelCount = Number(data?.old_travel_no_of_traveler) || 1;
    const travelersArrays = this?.travelersArrays[index] || [];

    if (Array.isArray(travelersArrays) && travelersArrays.length > 0) {
      travelersArrays.forEach((travelersGroup) => {
        travelersGroup.forEach((traveler,tIndex) => {
          const nestedRow = [
            String.fromCharCode(65 + tIndex),  
            traveler?.name || "",
            data?.tour_id?.title || "",
            data?.old_travel_no_of_traveler || "",
            traveler?.mobile || "",
             "",
             "",
             "",
             "",
            formatDate(data?.payment_time),
            data?.status || "",
            traveler?.email || "",
            traveler?.DOB || "",
            traveler?.country || "",
            traveler?.packageType || "",
            traveler?.pincode || "",
            traveler?.roomSharing || "",
            traveler?.state || "",
            traveler?.Idproof || "",
            traveler?.IdNumber || "",
            traveler?.age || "",
            traveler?.boarding_point || "",
            traveler?.city || "",
          ];
          rows.push(nestedRow);
        });
      });
    } else {
      for (let j = 0; j < travelCount; j++) {
        const blankRow =  String.fromCharCode(65 + j);
        rows.push(blankRow);

        
      }
    }
  });

  autoTable(doc, {
    head: [header],
    body: rows,
    startY: 20,
    theme: 'grid',
    styles: {
      
      fontSize: 9, // Font size
      cellPadding: 1, // Padding
      overflow: 'linebreak',
      halign: 'left', // Default alignment
      valign: 'middle',
    },
    headStyles: {
      fillColor: [45, 65, 84],
      textColor: [255, 255, 255],
      fontStyle: 'bold',
    },
    columnStyles: {
      0: { cellWidth: 10, halign: 'left' },  // S. No
      1: { cellWidth: 25, halign: 'left' },    // User Name
      2: { cellWidth: 30, halign: 'left' },    // Tour Name
      3: { cellWidth: 20, halign: 'left' },  // Tour User No
      4: { cellWidth: 20, halign: 'left' },  // Mobile Number
      5: { cellWidth: 25, halign: 'left' },  // Mobile Number
      6: { cellWidth: 15, halign: 'left' },  // Amount
      7: { cellWidth: 15, halign: 'left' },
      8: { cellWidth: 15, halign: 'left' },
      9: { cellWidth: 25, halign: 'left' },  // dat
      10: { cellWidth: 15, halign: 'center' },  // Status
      11: { cellWidth: 30, halign: 'center' },    // Email
      12: { cellWidth: 15, halign: 'left' },  // DOB
      13: { cellWidth: 20, halign: 'left' },    // Country
      14: { cellWidth: 15, halign: 'left' },   // Package Type
      15: { cellWidth: 15, halign: 'left' }, // Pincode
      16: { cellWidth: 15, halign: 'left' }, // Room Sharing
      17: { cellWidth: 15, halign: 'left' },   // State
      18: { cellWidth: 15, halign: 'left' }, // ID Proof
      19: { cellWidth: 15, halign: 'left' }, // ID Number
      20: { cellWidth: 10 ,halign: 'left' }, // Age
      21: { cellWidth: 15, halign: 'left' },   // Boarding Point
      22: { cellWidth: 20 , halign: 'left' },   // City
    },
    margin: { top: 20, left:0  }, // Reduced top margin
  });

  
  doc.save('GlabolUserDetails.pdf');
  this.spinner.hide(); 
}










check(data:any){
  const dataNumber=[]
for (let i = 0; i < data; i++) {
  dataNumber.push(i)
  
}
return dataNumber
}

admin() {
  // console.log(this.addLanguageListForm.value)
  this.apiService.getAllAdmin().subscribe((resp: any) => {
    console.log(resp);
    this.allData = resp.getData;
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  });
}



ontourtypeSelectt(id: any,  name: string) {
  console.log('Selected Name:', id)
  this.destination = id

  this.apiService.tourName( this.destination).subscribe((res: any) => {
    //console.log(res, 'heeeeeeee');
    this.allTour = res.data;
    console.log(this.allTour);
    
    // this.dtTrigger.next();
    // this.isDtInitialized = true;
        // this.render = 0;
  });

}


desti(){
  this.spinner.show()
  this.apiService.allMenuMatser().subscribe((resp: any) => {
      this.alldesti = resp.data
      console.log(this.alldesti)
      this.spinner.hide()
 });      
}


  
}