<section class="content">
 
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
             
              <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select Tour Type</label>
                  <select class="form-control" [(ngModel)]="tourtype">
                    <option value="" selected>Select Tour Type</option>
                    <option value="normal">Normal</option>
                    <option value="customized">Customized</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select</label>
                  <select class="form-control" [(ngModel)]="domesticorInternational">
                    <option value="" selected>Select Domestic/International</option>
                    <option value="domestic">Domestic</option>
                    <option value="international">International</option>
                  </select>
                </div>
              </div>


              <div class="col-md-3">
                <div class="form-group">
                      <label for="class_name">Select Destination</label>
                      <select class="form-control"
                            (change)="ontourtypeSelectt($event.target.value, $event.target.options[$event.target.selectedIndex].text);">
                            <option value="" selected>Select Destination</option>
                            <option *ngFor="let data of  alldesti" [value]="data._id
                    ">{{ data?.menu}}</option>
                      </select>
                </div>
          </div>

              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select Status <span class="required"></span></label>
                  <select class="form-control" [(ngModel)]="status">
                    <option value="" selected>Select Status</option>
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-3" >
                <div class="form-group">
                  <label for="class_name">Select Tour</label>
                  <select class="form-control" [(ngModel)]="tourname">
                    <option value="" selected>Select Tour</option>
                    <option *ngFor="let year of this.tourData" [value]="year._id
                    ">{{ year.title }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select Month</label>
                  <select class="form-control" [(ngModel)]="month">
                    <option value="" selected>Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </div>


              <div class="form-group col-md-3">
                <label for="class_name">Select Financial Year <span class="required"></span></label>
                <select class="form-control" [(ngModel)]="selectFinancialyear">
                  <option value="" [selected]="true" [disabled]="true">Select Year</option>
                  <option value={{year}} *ngFor="let year of getFinancialYear; let i = index">{{year}}</option>
                </select>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="class_name">Select Status <span class="required"></span></label>
                  <select class="form-control" [(ngModel)]="status">
                    <option value="" selected>Select Status</option>
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                  </select>
                </div>
              </div>
           
            <div class="col-md-4">
              <div class="form-group">
                <label for="class_name"></label>
                <button type="submit" class="btn btn-info" style="margin-top: 32px" (click)="serach()">
                  Search
                </button>
                &nbsp;
                <button type="submit" class="btn btn-info" style="margin-top: 32px" (click)="enquiry()">
                  Reset
                </button>
              </div>
            </div>
          </div>
          <button class="btn btn-info float-right mb-3" *ngIf="eq==true" (click)="closeenquiry()">
            All Close Enquiry
          </button>

          <button class="btn btn-info float-right mb-3" *ngIf="eq==false" (click)="openenquiry()">
            All Open Enquiry
          </button>
          <!-- <a [routerLink]="['/super-admin']" class="btn btn-info float-right mb-3">Add Admin</a> -->
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>No.of Travellers</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Destination Name</th>
                <th>Month</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="allData?.length != 0">
              <tr *ngFor="let data of allData?.allData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ data?.name }}</td>
                <td>{{data?.numberOfTraveller}}</td>
                <td>{{data?.mobile}}</td>
                <td>{{ data.email }}</td>
                <td>{{data?.destination || data?.tour_name}}</td>
                <td>{{ data?.traveler_month }}</td>
                <td style="color: red; font-weight: bold;" *ngIf="data.status=='Close'">
                  {{data.status}}
                </td>
                <td style="color: green; font-weight: bold;" *ngIf="data.status=='Open'">
                  {{data.status}}
                </td>
                <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-info"
                      routerLink="/enquiry-list/enquiry-detail/{{ data._id }}"><i class="fas fa-pen"></i></button>
                    <button type="button" class="btn btn-warning" (click)="deleteenquiry(data._id)"><i
                        class="fas fa-trash-alt"></i></button></div>
                </td>

                <!-- <td>
                  <div class="btn-group">
                  
                    <button
                      *ngIf="data.status === 'Open'"
                      type="button"
                      class="btn btn-success"
                      (click)="deleteenquiry(data._id, data.status)"
                    ><i class="far fa-check-circle"></i></button>
                    <button
                      *ngIf="data.status !== 'Open'"
                      type="button"
                      class="btn btn-danger"
                      (click)="deleteenquiry(data._id, data.status)"
                    ><i class="fas fa-ban"></i></button>
                  </div>
                </td> -->
              </tr>
            </tbody>
            <tbody *ngIf="allData?.length == 0">
              <tr>
                <td colspan="3" class="no-data-available">No data!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>