import { Component, OnInit, OnDestroy, ViewChild  } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-user-reminder',
  templateUrl: './user-reminder.component.html',
  styleUrls: ['./user-reminder.component.scss']
})
export class UserReminderComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  public allData: any;
  private isDtInitialized: boolean = false;
  public timeDate: any = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  currentPage: number = 1;

data: any;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.reminder();
  }

  reminder(page: number = 1) {
    this.apiService.getReminderr(page).subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
      let reminderData = [];
      this.timeDate = [];
      
      // Loop through the data and filter based on conditions
      for (let date of this.allData) {
        let addonsAmount = 0;
  
        if (
          Math.ceil((new Date(date.applyTour_id.tourReminderEndDate).getTime() - Date.now()) / (1000 * 3600 * 24)) >= 0 &&
          date.applyTour_id.payment_status === "Partial"
        ) {
          this.timeDate.push(Math.ceil((new Date(date.applyTour_id.tourReminderEndDate).getTime() - Date.now()) / (1000 * 3600 * 24)));
          reminderData.push(date);
  
          // Addons amount calculation
          if (date.applyTour_id.addons_id) {
            date.applyTour_id.addons_id.addons_data.forEach((elem) => {
              addonsAmount += elem.price * elem.quantity;
              date.applyTour_id.addons_id = addonsAmount;
            });
          } else {
            date.applyTour_id.addons_id = addonsAmount;
          }
        }
      }
  
      // Update allData with filtered data
      // this.allData = reminderData;
  
      // Reinitialize DataTable if it was already initialized
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  
  calculateDiff(startDate: string, endDate: string) {
    return Math.ceil(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        (1000 * 3600 * 24)
    );
  }


 ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  onPageChange(page: number) {
  this.currentPage = page;
  this.reminder(page); // Call reminder method with the updated page number
}
}
  