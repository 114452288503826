<div class="content">
      <div class="container-fluid">
            <div *ngIf="this.pricingid" class="card card-primary">
                  <div class="card-body">
                        <div class="tab-container">
                              <hr style="border-top: 1px solid black; width: auto;">
                              <hr style="border-top: 1px solid black; width: 0%;">

                              <!-- Define tabs for each page with routerLinkActive directive -->
                              <div class="tab circular-button navbar-nav" routerLinkActive="active"
                                    [routerLink]="['/make-trip', this.object]" title="Make Trip">1</div>

                              <hr style="border-top: 1px solid black; width: 300%;">
                              <hr style="border-top: 1px solid black; width: 400%;">

                              <div class="tab circular-button" routerLinkActive="active"
                                    [routerLink]="['/make-trip-date', this.object]" title="Make Trip Date">2</div>

                              <hr style="border-top: 1px solid black; width: 300%;">
                              <hr style="border-top: 1px solid black; width: 400%;">

                              <div class="tab circular-button" routerLinkActive="active"
                                    [routerLink]="['/make-trip-overview', this.object]" title="Make Trip Overview">3
                              </div>

                              <hr style="border-top: 1px solid black; width: 300%;">
                              <hr style="border-top: 1px solid black; width: 400%;">

                              <div class="tab circular-button" routerLinkActive="active"
                                    [routerLink]="['/make-trip-itinerary', this.object]" title="Make Trip Itinerary">4
                              </div>

                              <hr style="border-top: 1px solid black; width: 300%;">
                              <hr style="border-top: 1px solid black; width: 400%;">

                              <div class="tab circular-button" routerLinkActive="active"
                                    [routerLink]="['/make-trip-inclusion', this.object]" title="Make Trip Inclusion">5
                              </div>

                              <hr style="border-top: 1px solid black; width: 156%;">
                              <hr style="border-top: 1px solid black; width: 500%;">

                              <div class="tab circular-button" routerLinkActive="active"
                                    [routerLink]="['/make-trip-pricing-list', this.object]" title="Make Trip Pricing">6
                              </div><br>

                              <hr style="border-top: 1px solid black; width: auto;">
                        </div>
                  </div>

            </div>
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addMakeTripTwoForm" (ngSubmit)="addMakeTourTwo(id)">

                                    <br />
                                    <div class="card-body">

                                          <div class="row">
                                               

                                              
                                                <div class="col-md-6">
                                                      <div class="form-group">
                                                            <label for="class_name">Title<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" placeholder="Title"
                                                                  formControlName="overview_title">
                                                      </div>
                                                </div>
                                                <div class="col-md-12">
                                                      <div class="form-group">
                                                            <label for="class_name">Overview<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck" formControlName="overview">

                                                            </ckeditor>
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Region<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" placeholder="Region"
                                                                  formControlName="region">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">High Altitude<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="High Altitude"
                                                                  formControlName="highAltitude">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Pick & Drop<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Pick & Drop"
                                                                  formControlName="pickAndDrop">
                                                      </div>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Trip/Trek <span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Trip/Trek"
                                                                  formControlName="tripAndTrekType">
                                                      </div>
                                                </div> -->
                                                <!-- <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Name Of Places (Enter Places With
                                                                  ,)<span class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Name Of Place"
                                                                  formControlName="nameOfPlace">
                                                      </div>
                                                </div> -->
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Number Of Places<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Number Of Places"
                                                                  formControlName="no_of_place">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Difficulty<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Difficulty" formControlName="difficulty">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Best Time<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Best Time" formControlName="best_time">
                                                      </div>
                                                </div>

                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                          <label for="class_name">Whatsapp Number</label>
                                                          <input type="text" class="form-control" formControlName="whatsapp_number"
                                                              placeholder="Whatsapp Number">
                                                      </div>
                                                  </div>

                                                  <!-- <div class="col-md-8">
                                                      <div class="form-group">
                                                          <label for="class_name">Multiple Phone Number Number(When entering multiple numbers, put a comma separator after one number.)</label>
                                                          <input type="text" class="form-control" formControlName="multiple_number"
                                                              placeholder="Multiple Phone Number Number Please comma Sepreter">
                                                      </div>
                                                  </div> -->


                                                <div class="col-md-12">
                                                      <div class="form-group ">

                                                            <label for="class_name">Overview Note</label>
                                                            <ckeditor [config]="config_ck" formControlName="overviewNote">
                        
                                                            </ckeditor>
                        
                                                        </div>
                                                </div>


                                             
                                                <div class="col-md-4">
                                                      <button type="button" class="btn btn-success mt-2"
                                                            (click)="addAdditionalInfo()">
                                                            <i class="fas fa-plus-circle"></i>
                                                      </button>
                                                </div>

                                                <div class="row"
                                                      *ngFor="let group of additionalInfo.controls; let i = index;"
                                                      formArrayName="multiple_overviewdesc">
                                                      <div class="col-md-12">
                                                            <div class="row form-group" [formGroupName]='i'>

                                                                  <div class="col-md-6">
                                                                        <label for="class_name">Overview Title</label>
                                                                        <input type="text" class="form-control"
                                                                              placeholder="Overview Title "
                                                                              formControlName="overview_title">
                                                                  </div>

                                                                  <div class="col-md-12">
                                                                        <label for="class_name">Overview
                                                                              Description</label>
                                                                        
                                                                        <ckeditor [config]="config_ck"
                                                                              formControlName="overview_desc">
                                                                        </ckeditor>
                                                                  </div>

                                                                  <div class="col-md-2">
                                                                        <button type="button"
                                                                              class="btn btn-success mt-2 "
                                                                              (click)="addAdditionalInfo()">
                                                                              <i class="fas fa-plus-circle"></i>
                                                                        </button>&nbsp;
                                                                        <button type="button"
                                                                              class="btn btn-danger mt-2"
                                                                              (click)="removeAdditionalInfo(i)">
                                                                              <i class="fas fa-minus-circle "></i>
                                                                        </button>
                                                                  </div>

                                                            </div>
                                                      </div>
                                                </div>


                                                <!-- <div class="form-group col-md-12" #div>
                                                </div>

                                                <div id="dynamic_fields"></div>

                                                <div class="row" style="margin-left: 1px;">
                                                      <div class="form-group col-md-12">
                                                            <button type="button" (click)="addElement()"
                                                                  class="btn btn-info">Add</button>
                                                      </div>
                                                </div> -->
                                          </div>
                                    </div>
                                    <!-- <div class="card-body" style="margin-top: -42px;">
                                          <div class="row">
                                                <div class="col-md-4">

                                                      <div class="custom-control custom-checkbox">
                                                            <input id="male" type="checkbox"
                                                                  class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                  for="male">Status</label>
                                                      </div>
                                                </div>
                                          </div>
                                    </div> -->
                                    <!-- /.card-body -->
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Next'"
                                                [loading]="isAuthLoading" [color]="'info'" [disabled]="isAuthDisabled">
                                          </app-button>

                                          <button type="submit" class="btn btn-info"
                                                [routerLink]="['/make-trip-date', object]"
                                                style="float:left;">Previous</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>