<section class="content">
  <div class="row" *ngIf="!value; else dothat">
    <div class="col-md-4">
      <div class="form-group">
        <label for="class_name"
          >Select Month<span class="required"></span
        ></label>
        <select class="form-control" [(ngModel)]="month">
          <option value="" selected>Select Month</option>
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="class_name"
          >Select Year<span class="required"></span
        ></label>
        <select class="form-control" [(ngModel)]="year">
          <option value="" selected>Select Years</option>
          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>

      
        <!-- <select
          class="form-control"
          id="financialYear"
          [(ngModel)]="year"
        ></select> -->
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="class_name"
          >Select Tax Type<span class="required"></span
        ></label>
        <select class="form-control" [(ngModel)]="type">
          <option value="" selected>Select Tax Type</option>
          <option value="igst">IGST</option>
          <option value="cgst">CGST</option>
          <option value="sgst">SGST</option>
          <option value="tcs">TCS</option>
          
        </select>
      </div>
    </div>
    <!-- <div class="col-md-4">
      <div class="form-group">
        <label for="class_name"
          >Select Financial Year<span class="required"></span
        ></label>
        <select
          class="form-control"
          id="financialYear"
          [(ngModel)]="year"
        ></select>
      </div>
    </div> -->
    <div class="col-md-4">
      <div class="form-group">
        <label for="class_name"></label>
        <button
          type="submit"
          class="btn btn-info"
          style="margin-top: 32px"
          (click)="date()"
        >
          Search
        </button>
        &nbsp;
        <button
          type="submit"
          class="btn btn-info"
          style="margin-top: 32px"
          (click)="allTour()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
  <ng-template #dothat>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="class_name"
            >Select Financial Year<span class="required"></span
          ></label>
          <select
            class="form-control"
            id="financialYear"
            [(ngModel)]="selectFinancialyear"
          >
              <option value="Select Year" [selected]="true" [disabled]="true">Select Year</option>
              <option value={{year}} *ngFor="let year of getFinancialYear; let i = index">{{year}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="class_name"></label>
          <button
            type="submit"
            class="btn btn-info"
            style="margin-top: 32px"
            (click)="financialList()"
          >
            Financial Search
          </button>
          &nbsp;
          <button
          type="submit"
          class="btn btn-info"
          style="margin-top: 32px"
          (click)="allTour()"
        >
          Reset
        </button>
        </div>
      </div>
    </div>
  </ng-template>
  <button (click)="value = !value" class="btn btn-info mb-3">{{value? 'Monthly Search':'Financial Search'}}</button>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- [dtTrigger]="dtTrigger"-->
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover w-100"
            id="example"
          >
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Tour Name</th>
                <th>Client Name</th>
                <th>State</th>
                <!-- <th>Client GST No.</th> -->
                <th>Invoice No.</th>
                <th>Booking Date</th>
                <th>Invoice Date</th>
                <th>Tour Price</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>IGST</th>
                <th>TCS</th>
                <th>Total</th>
                <th>Invoice</th>
                <th>Invoice Pdf</th>
              </tr>
            </thead>
            <tfoot *ngIf="allData?.length !== 0">
              <tr class="font-weight-bold">
                <td>#</td>
                <td colspan="5" class="text-center">Total Tax Amount</td>
                <td>-</td>
                <td>{{ getTotal('tourPrice') }}</td>
                <td>{{ getTotal('amtWithCGSTNewCharges') }}</td>
                <td>{{ getTotal('amtWithSGSTNewCharges') }}</td>
                <td>{{ getTotal('amtWithIGSTNewCharges') }}</td>
                <td>{{ getTotal('amtWithTCSNewCharges') }}</td>
                <td>{{ getTotal('amtWithNewCharges') }}</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tfoot>
            
            <tbody *ngIf="allData?.length != 0">
              <tr *ngFor="let data of allData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ data.tour_id?.title
                  }}{{ data.totalAddon !== 0 ? '*' : '' }}
                </td>
                <td>
                  {{ data?.user_id?.firstname }} {{ data?.user_id?.lastname }}
                </td>
                <!-- <td>{{ data.cusGSTNo ? data.cusGSTNo : '-' }}</td> -->
                <td>{{ data?.user_id?.state }}</td>
                <td>{{ data?.invoice_num ? data.invoice_num : '-' }}</td>
                <td>{{ data?.created_at | date: 'dd-MM-yy' }}</td>
                <td>{{ data?.created_at | date: 'dd-MM-yy' }}</td>
                <!-- * tour price -->
                <td>
                  {{
                    (data.appliedCoupon
                      ? data.finalPrice - +data.appliedCoupon
                      : data.finalPrice) + data.totalAddon
                  }}
                </td>
                <!-- * CGST -->
                <td>
                  <!-- {{
                    !data.isOuter && data.cgst
                      ? (
                          (((data.appliedCoupon
                            ? data.finalPrice - +data.appliedCoupon
                            : data.finalPrice) +
                            data.totalAddon) *
                            data.cgst *
                            0.01) /
                          (1 + data.cgst * 0.01 + data.sgst * 0.01)
                        ).toFixed(2)
                      : '-'
                  }} -->

                  {{
                    data?. amtWithCGSTNewCharges.toFixed(2)
                  }}
                </td>
                <!-- * SGST -->
                <td>
                  <!-- {{
                    !data.isOuter && data.sgst
                      ? (
                          (((data.appliedCoupon
                            ? data.finalPrice - +data.appliedCoupon
                            : data.finalPrice) +
                            data.totalAddon) *
                            data.sgst *
                            0.01) /
                          (1 + data.cgst * 0.01 + data.sgst * 0.01)
                        ).toFixed(2)
                      : '-'
                  }} -->

                  {{
                    data?.amtWithSGSTNewCharges
                    .toFixed(2)
                  }}
                </td>
                <!-- * IGST -->
                <td>
                  <!-- {{
                    data.isOuter && data.igst
                      ? (
                          (((data.appliedCoupon
                            ? data.finalPrice - +data.appliedCoupon
                            : data.finalPrice) +
                            data.totalAddon) *
                            data.igst *
                            0.01) /
                          (1 + data.igst * 0.01)
                        ).toFixed(2)
                      : '-'
                  }} -->

                  {{
                    data?. amtWithIGSTNewCharges.toFixed(2)
                  }}
                </td>
                <td>
                  <!-- {{
                    data.isOuter && data.amtWithTCSNewCharges
                      ? (
                          (((data.appliedCoupon
                            ? data.finalPrice - +data.appliedCoupon
                            : data.finalPrice) +
                            data.totalAddon) *
                            data.amtWithTCSNewCharges *
                            0.01) /
                          (1 + data.amtWithTCSNewCharges * 0.01)
                        ).toFixed(2)
                      : '-'
                  }} -->
                {{data?.amtWithTCSNewCharges.toFixed(2)}}
                </td>
                <!-- * total -->
                <td>
                  <!-- {{
                    !data.isOuter
                      ? data.cgst && data.sgst
                        ? (
                            (((data.appliedCoupon
                              ? data.finalPrice - +data.appliedCoupon
                              : data.finalPrice) +
                              data.totalAddon) *
                              data.cgst *
                              0.01) /
                              (1 + data.cgst * 0.01 + data.sgst * 0.01) +
                            (((data.appliedCoupon
                              ? data.finalPrice - +data.appliedCoupon
                              : data.finalPrice) +
                              data.totalAddon) *
                              data.sgst *
                              0.01) /
                              (1 + data.cgst * 0.01 + data.sgst * 0.01)
                          ).toFixed(2)
                        : '-'
                      : data.igst
                      ? (
                          (((data.appliedCoupon
                            ? data.finalPrice - +data.appliedCoupon
                            : data.finalPrice) +
                            data.totalAddon) *
                            data.igst *
                            0.01) /
                          (1 + data.igst * 0.01)
                        ).toFixed(2)
                      : '-'
                  }} -->

                  {{
                    data?.amtWithNewCharges
                    .toFixed(2)
                  }}
                </td>
                <td>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-info"
                      [routerLink]="[
                        '/user-order-history/particular-order/' + data._id
                      ]"
                    >
                      Order History
                    </button>
                  </div>
                </td>
                <td><div class="py-1">
                  <button class="btn btn-curve my-2"
                      
                      (click)="sendDataToGenerateInvoice(data)"
                      data-bs-toggle="tooltip" data-bs-placement="top"
                      title="INVOICE">INVOICE <i class="far fa-file-pdf"
                          aria-hidden="true"></i>
                  </button>
              </div>
            </td>
              </tr>
            </tbody>
            <tbody *ngIf="allData?.length == 0">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>No data!</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div class="text-right">* Tour amount includes addons amount</div>
        </div>
      </div>
    </div>
  </div>
</section>
