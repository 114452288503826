import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
filterStart=1
filterEnd=24
  constructor() { }
}
