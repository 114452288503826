import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from "ngx-bootstrap-spinner";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from './views/blank/blank.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppButtonComponent } from './components/app-button/app-button.component';


import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserDropdownMenuComponent } from './pages/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { AdminListComponent } from './views/admin-list/admin-list.component';
//import { DashboardVideoListComponent } from './views/dashboard-video-list/dashboard-video-list.component';
import { UserListComponent } from './views/user-list/user-list.component';
import { PageHeaderComponent } from './pages/main/page-header/page-header.component';
import { FabricEditorComponent } from './utils/helpers/fabric-editor/fabric-editor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './utils/guards/auth.guard';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

import { FirstWordPipe } from './utils/pipes/first-word.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { BlogListComponent } from './views/blog-list/blog-list.component';
import { BlogComponent } from './views/blog/blog.component';
import { BoardingPointComponent } from './views/boarding-point/boarding-point.component';
import { AdventureTypeComponent } from './views/adventure-type/adventure-type.component';
import { PackageTypeComponent } from './views/package-type/package-type.component';
import { DestinationCityComponent } from './views/destination-city/destination-city.component';
import { MakeTripComponent } from './views/make-trip/make-trip.component';
import { TestComponent } from './views/test/test.component';
import { BoardingPointListComponent } from './views/boarding-point-list/boarding-point-list.component';
import { PackageTypeListComponent } from './views/package-type-list/package-type-list.component';
import { AdventureTypeListComponent } from './views/adventure-type-list/adventure-type-list.component';
import { DestinationCityListComponent } from './views/destination-city-list/destination-city-list.component';
import { MakeTripOverviewComponent } from './views/make-trip-overview/make-trip-overview.component';
import { MakeTripItineraryComponent } from './views/make-trip-itinerary/make-trip-itinerary.component';
import { MakeTripInclusionComponent } from './views/make-trip-inclusion/make-trip-inclusion.component';
import { MakeTripPricingListComponent } from './views/make-trip-pricing-list/make-trip-pricing-list.component';
import { SuperAdminComponent } from './views/super-admin/super-admin.component';
import { SuperAdminListComponent } from './views/super-admin-list/super-admin-list.component';
import { AddUserComponent } from './views/add-user/add-user.component';
import { AddUserListComponent } from './views/add-user-list/add-user-list.component';
import { AddMediaComponent } from './views/add-media/add-media.component';
import { AddMediaListComponent } from './views/add-media-list/add-media-list.component';
import { AddVideoComponent } from './views/add-video/add-video.component';
import { AddVideoListComponent } from './views/add-video-list/add-video-list.component';
import { MakeTripListComponent } from './views/make-trip-list/make-trip-list.component';
import { AddCheckoutNotesComponent } from './views/add-checkout-notes/add-checkout-notes.component';
import { CoupanCodeComponent } from './views/coupan-code/coupan-code.component';
import { CoupanCodeListComponent } from './views/coupan-code-list/coupan-code-list.component';
import { AddCheckoutNotesListComponent } from './views/add-checkout-notes-list/add-checkout-notes-list.component';
import { CkEditorComponent } from './views/ck-editor/ck-editor.component';
import { AddUserOrdersComponent } from './views/add-user-orders/add-user-orders.component';
import { UserHistoryComponent } from './views/user-history/user-history.component';
import { SpecialTourComponent } from './views/special-tour/special-tour.component';
import { SpecialTourListComponent } from './views/special-tour-list/special-tour-list.component';
import { OtherChargesComponent } from './views/other-charges/other-charges.component';
import { OtherChargesListComponent } from './views/other-charges-list/other-charges-list.component';
import { UserOrderHistoryComponent } from './views/user-order-history/user-order-history.component';
import { ReportComponent } from './views/report/report.component';
import { AddRoomSharingComponent } from './views/add-room-sharing/add-room-sharing.component';
import { ListRoomSharingComponent } from './views/list-room-sharing/list-room-sharing.component';
import { BikeOptionsComponent } from './views/bike-options/bike-options.component';
import { BikeOptionsListComponent } from './views/bike-options-list/bike-options-list.component';
import { LandingReportComponent } from './views/landing-report/landing-report.component';
import { ViewLandingDetailComponent } from './views/view-landing-detail/view-landing-detail.component';
import { UserParticularOrderComponent } from './views/user-particular-order/user-particular-order.component';
import { TaxListComponent } from './views/tax-list/tax-list.component';
import { RefundCancelOrderComponent } from './views/refund-cancel-order/refund-cancel-order.component';
import { AddMenuComponent } from './views/add-menu/add-menu.component';
import { AddMenuListComponent } from './views/add-menu-list/add-menu-list.component';
import { RefundListComponent } from './views/refund-list/refund-list.component';
import { PayLinkOrdersComponent } from './views/pay-link-orders/pay-link-orders.component';
//import { MakeTripComponent } from './pages/make-trip/make-trip.component';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NotificationViewComponent } from './views/notification-view/notification-view.component';
import { ReminderDropdownMenuComponent } from './pages/main/header/reminder-dropdown-menu/reminder-dropdown-menu.component';

import { environment } from "../environments/environment";
import { UserReminderComponent } from './views/user-reminder/user-reminder.component';
import { CoupanAssignComponent } from './views/coupan-assign/coupan-assign.component';
import { AddVoucherComponent } from './views/add-voucher/add-voucher.component';
import { AddVoucherListComponent } from './views/add-voucher-list/add-voucher-list.component';
import { VoucherUserListComponent } from './views/voucher-user-list/voucher-user-list.component';
import { RefundListTaxComponent } from './views/refund-list-tax/refund-list-tax.component';
import { UserTourDetailsComponent } from './views/user-tour-details/user-tour-details.component';
import { EnquiryListComponent } from './views/enquiry-list/enquiry-list.component';
import { TourUserDetailsComponent } from './views/tour-user-details/tour-user-details.component';
import { PromotionalSmsEmailComponent } from './views/promotional-sms-email/promotional-sms-email.component';
import { EnquiryDetailComponent } from './views/enquiry-detail/enquiry-detail.component';
import { LableComponent } from './views/lable/lable.component';
import { LableListComponent } from './views/lable-list/lable-list.component';



import { AddMenuMasterComponent } from './views/add-menu-master/add-menu-master.component';
import { MenuMappingComponent } from './views/menu-mapping/menu-mapping.component';
import { SetMenuComponent } from './views/set-menu/set-menu.component';
import { AddMenuMasterListComponent } from './views/add-menu-master-list/add-menu-master-list.component';
import { MakeTripDateComponent } from './views/make-trip-date/make-trip-date.component';
import { PromotionalSmsEmailListComponent } from './views/promotional-sms-email-list/promotional-sms-email-list.component';
import { AddMenuMasterNewComponent } from './views/add-menu-master-new/add-menu-master-new.component';
import { AddMenuMasterNewListComponent } from './views/add-menu-master-new-list/add-menu-master-new-list.component';
import { UserAllTranscationComponent } from './views/user-all-transcation/user-all-transcation.component';
import { AgentBookingOrderComponent } from './views/agent-booking-order/agent-booking-order.component';
import { TravelledReportComponent } from './views/travelled-report/travelled-report.component';
import { BookedReportComponent } from './views/booked-report/booked-report.component';




registerLocaleData(localeEn, 'en-EN');
// const config: SocketIoConfig = { url: environment.API_HOST, options: {transports: ['polling']} };

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    AppButtonComponent,
    UserDropdownMenuComponent,
    AdminListComponent,
    UserListComponent,
    PageHeaderComponent,
    FabricEditorComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    FirstWordPipe,
    BlogListComponent,
    BlogComponent,
    BoardingPointComponent,
    AdventureTypeComponent,
    PackageTypeComponent,
    DestinationCityComponent,
    MakeTripComponent,
    TestComponent,
    BoardingPointListComponent,
    PackageTypeListComponent,
    AdventureTypeListComponent,
    DestinationCityListComponent,
    MakeTripOverviewComponent,
    MakeTripItineraryComponent,
    MakeTripInclusionComponent,
    MakeTripPricingListComponent,
    SuperAdminComponent,
    SuperAdminListComponent,
    AddUserComponent,
    AddUserListComponent,
    AddMediaComponent,
    AddMediaListComponent,
    AddVideoComponent,
    AddVideoListComponent,
    MakeTripListComponent,
    AddCheckoutNotesComponent,
    CoupanCodeComponent,
    CoupanCodeListComponent,
    AddCheckoutNotesListComponent,
    CkEditorComponent,
    AddUserOrdersComponent,
    UserHistoryComponent,
    SpecialTourComponent,
    SpecialTourListComponent,
    OtherChargesComponent,
    OtherChargesListComponent,
    UserOrderHistoryComponent,
    ReportComponent,
    AddRoomSharingComponent,
    ListRoomSharingComponent,
    BikeOptionsComponent,
    BikeOptionsListComponent,
    LandingReportComponent,
    ViewLandingDetailComponent,
    UserParticularOrderComponent,
    TaxListComponent,
    RefundCancelOrderComponent,
    AddMenuComponent,
    AddMenuListComponent,
    RefundListComponent,
    PayLinkOrdersComponent,
    NotificationViewComponent,
    ReminderDropdownMenuComponent,
    UserReminderComponent,
    CoupanAssignComponent,
    AddVoucherComponent,
    AddVoucherListComponent,
    VoucherUserListComponent,
    RefundListTaxComponent,
    UserTourDetailsComponent,
    EnquiryListComponent,
    TourUserDetailsComponent,
    PromotionalSmsEmailComponent,
    EnquiryDetailComponent,
    AddMenuMasterComponent,
    MenuMappingComponent,
    SetMenuComponent,
    AddMenuMasterListComponent,
    MakeTripDateComponent,
    LableComponent,
    LableListComponent,
    PromotionalSmsEmailListComponent,
    AddMenuMasterNewComponent,
    AddMenuMasterNewListComponent,
    UserAllTranscationComponent,
    AgentBookingOrderComponent,
    TravelledReportComponent,
    BookedReportComponent,

    
 
    //MakeTripComponent,
  ],
  imports: [
    // SocketIoModule.forRoot(config),
    BrowserModule,
    // NgDragDropModule.forRoot(),
    AppRoutingModule,
   
    NgxSpinnerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbModule,
    DataTablesModule,
    HttpClientModule,
    ColorPickerModule,
    CKEditorModule,
    FormsModule,
    NgSelectModule,

    
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
